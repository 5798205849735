import 'zone.js/dist/zone-mix';
import 'reflect-metadata';
import '../polyfills';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PlotlyModule} from 'angular-plotly.js';

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

// NG Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ElectronService } from './providers/electron.service';

import { WebviewDirective } from './directives/webview.directive';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SplashComponent} from './components/splash/splash.component';
import {
  MatCardModule,
  MatCheckboxModule, MatExpansionModule, MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule, MatSidenavModule,
  MatTabsModule,
  MatToolbarModule,
  MatSelectModule, MatButtonModule, MatRadioModule, MatChipsModule, MatAutocompleteModule, MatButtonToggleModule, MatDialogModule
} from '@angular/material';
import { CellLineAncestryPlotComponent } from './components/cell-line-ancestry-plot/cell-line-ancestry-plot.component';
import { CellLineAncestryPlotFromDataComponent } from './components/cell-line-ancestry-plot-from-data/cell-line-ancestry-plot-from-data.component';
import {MyPlotlyService} from './providers/my-plotly.service';
import {AgGridModule} from 'ag-grid-angular';
import {AboutDialogComponent} from './components/about-dialog/about-dialog.component';
// import { AncestrySideBarPlotComponent } from './components/ancestry-side-bar-plot/ancestry-side-bar-plot.component';
// import { CellLineAncestryPcaPlotComponent } from './components/cell-line-ancestry-pca-plot/cell-line-ancestry-pca-plot.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SplashComponent,
    WebviewDirective,
    CellLineAncestryPlotComponent,
    CellLineAncestryPlotFromDataComponent,
    AboutDialogComponent
    // ,
    // AncestrySideBarPlotComponent,
    // CellLineAncestryPcaPlotComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    PlotlyModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatTabsModule,
    MatListModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatGridListModule,
    MatSidenavModule,
    MatSelectModule,
    MatButtonModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([]),
    MatButtonToggleModule,
    MatDialogModule
  ],
  entryComponents: [AboutDialogComponent],
  providers: [ElectronService, MyPlotlyService],
  bootstrap: [AppComponent]
})
export class AppModule { }
