export const kgAnnotations = {
  LWK: 'Luhya in Webuye, Kenya',
  ESN: 'Esan in Nigeria',
  YRI: 'Yoruba in Ibadan, Nigeria',
  MSL: 'Mende in Sierra Leone',
  GWD: 'Gambian in Western Divisions in the Gambia',
  ACB: 'African Caribbeans in Barbados',
  ASW: 'Americans of African Ancestry in SW USA',
  CLM: 'Colombians from Medellin, Colombia',
  MXL: 'Mexican Ancestry from Los Angeles USA',
  PUR: 'Puerto Ricans from Puerto Rico',
  PEL: 'Peruvians from Lima, Peru',
  TSI: 'Toscani in Italia',
  IBS: 'Iberian Population in Spain',
  GBR: 'British in England and Scotland',
  CEU: 'Utah Residents (CEPH) with Northern and Western European Ancestry',
  FIN: 'Finnish in Finland',
  PJL: 'Punjabi from Lahore, Pakistan',
  GIH: 'Gujarati Indian from Houston, Texas',
  ITU: 'Indian Telugu from the UK',
  STU: 'Sri Lankan Tamil from the UK',
  BEB: 'Bengali from Bangladesh',
  CDX: 'Chinese Dai in Xishuangbanna, China',
  KHV: 'Kinh in Ho Chi Minh City, Vietnam',
  CHS: 'Southern Han Chinese',
  CHB: 'Han Chinese in Beijing, China',
  JPT: 'Japanese in Tokyo, Japan'
}
