import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MyPlotlyService} from '../../providers/my-plotly.service';
import {kgAnnotations} from '../../../environments/data/1kg.annotations';

function hexToRgbA(hex){
  let c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
    c= hex.substring(1).split('');
    if(c.length== 3){
      c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c= '0x'+c.join('');
    return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
  }
  throw new Error('Bad Hex');
}

function hexToRgb(hex){
  let c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
    c= hex.substring(1).split('');
    if(c.length== 3){
      c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c= '0x'+c.join('');
    return 'rgb('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+')';
  }
  throw new Error('Bad Hex');
}


@Component({
  selector: 'app-cell-line-ancestry-plot-from-data',
  templateUrl: './cell-line-ancestry-plot-from-data.component.html',
  styleUrls: ['./cell-line-ancestry-plot-from-data.component.scss']
})
export class CellLineAncestryPlotFromDataComponent implements OnInit, OnChanges {

  /* This component does the following
   *  1. On data change, redraw everything
   *  2. On mode change, redraw everything
   *  3. On select points, highlight selected points
   *  4. On colorby change, change vertex color
   *  5. On combination of changes,
   *      a) 1 and any, redraw everything, highlight, set color if needed
   *      b) 2 and any, redraw everything, highlight, set color if needed
   *      c) 3 and any, highlight points, set color if needed
   *      d) 4 only, set color
   */
  // tsne coordinates, pcs
  @Input('data') data;
  // mode of display
  @Input('mode') mode;
  // color by
  @Input('colorBy') colorBy: {attr: string, continuous: boolean};
  @Input('search') search = [];
  // @Input('blurBackground') blurBackground = false;
  @ViewChild('plot') plot;
  @ViewChild('cellGrid') cellGrid;
  @ViewChild('refGrid') refGrid;
  @Output('searchResults') searchResults = new EventEmitter();

  refGridNotFitted = true;
  cellGridNotFitted = true;

  showWhat = 'plot';
  clCovRowData;
  clCovColumnDefs;
  refCovRowData;
  refCovColumnDefs;

  // default settings

/*
  defaultRefOpcacity = 1;
  defaultCellOpacity = 1;
  defaultRefSize = 6;
  defaultCellSize = 6;
*/
  defaultRefOpcacity = 0.5;
  defaultCellOpacity = 1;
  defaultRefSize = 10;
  defaultCellSize = 4;
  defaultRefSymbol = 'circle';
  // defaultRefSymbol = 'asterisk-open';
  // defaultRefSymbol = 'x';
  // defaultCellSymbol = 'square';
  defaultCellSymbol = 'circle';
  defaultRef3dSymbol = 'circle';
  // defaultCell3dSymbol = 'square';
  defaultCell3dSymbol = 'circle';
  defaultCellColor = 'rgba(110,10,110,1)';
  // defaultCellBorderColor = 'rbga(160,160,160,1)';
  defaultCellBorderColor = 'rbga(0,0,0,1)';

  defaultAnnotationOpcacity = 1;
  defaultAnnotationSize = 10;

  // highlight settings
  highlightOpacity = 0.7;
  highlightSize = 10;
  backgroundOpacity = 0.3;
  backgroundSize = 2;
  highlightBorderWidth = 1;
  backgroundBorderWidth = 0;


  colorScale;
  colorScale10;

  // intermediate data
  cellIdxs;
  refIdxs;
  selectedRefPoints;
  selectedCellPoints;
  selectedRefIdxs;
  selectedCellIdxs;
  cellTextData;
  refTextData;
  cellSymbolData;
  refSymbolData;
  // cellTs2;
  // refTs2;
  // cellCov;
  // refCov;
  // refColorData;
  // cellColorData;
  // cellBorderColorData;

  graph: any = {
    data: null,
    layout: null,
    // style: { width: '100%', height: '90%' },
    config: {
      displaylogo: false,
      modeBarButtonsToRemove: ['lasso2d', 'select2d', 'sendDataToCloud'],
      showLink: false
    },
    useResizeHandler: true
  };


  constructor(private plotlyService: MyPlotlyService) {
    this.colorScale = this.plotlyService.d3.scale.category20();
    this.colorScale10 = this.plotlyService.d3.scale.category10();
  }

  ngOnInit() {
  }

/*  markData(d) {
    const makeSelectedArray = (text, selectedV, notSelectedV ) =>
      Array.isArray(text) ?
        text.map(t => this.selectedPoints.some(
          p => t.match(new RegExp('\\b' + p + '\\b', 'i'))
        ) ? selectedV : notSelectedV) :
        this.selectedPoints.some(
          p => text.match(new RegExp('\\b' + p + '\\b', 'i'))
        ) ? selectedV : notSelectedV;

    // color looks like this  'rgba(0,0,0,0)'
    const oldcolv = d.marker.color;
    const col = oldcolv.slice(5, -1).split(',');
    const newcol = col.slice(0, -1).map(c => parseInt(c, 10))
      .map(c => c - 40 < 0 ? 0 : c - 40);
    newcol.push(col[col.length - 1]);
    const newcolv = 'rgba(' + newcol.join() + ')';
    if ( (!this.selectedPoints) || this.selectedPoints.length === 0) {
      // nothing is selected
      // set all markers' opacity to 1.0
      d.marker.opacity =  Array.isArray(d.text) ? d.text.map(t =>
          (t.startsWith('cell') ? this.defaultCellOpacity : this.defaultRefOpcacity)) :
        (d.text.startsWith('cell') ? this.defaultCellOpacity : this.defaultRefOpcacity);
      d.marker.size = Array.isArray(d.text) ? d.text.map(t => 6.0) : 6.0;
      d.marker.line = {
        color: newcolv,
        width: 1,
        opacity: Array.isArray(d.text) ? d.text.map(t =>
            (t.startsWith('cell') ? this.defaultCellOpacity : this.defaultRefOpcacity)) :
          (d.text.startsWith('cell') ? this.defaultCellOpacity : this.defaultRefOpcacity)
      };
    } else {
      d.marker.opacity = makeSelectedArray(d.text, 1.0, this.backgroundOpacity);
      d.marker.size = makeSelectedArray(d.text, 10.0, 4.0);
      d.marker.line = {};
      d.marker.line.color = makeSelectedArray(d.text, newcolv, oldcolv);
      d.marker.line.opacity = makeSelectedArray(d.text, 1.0, this.backgroundOpacity);
      d.marker.line.width = makeSelectedArray(d.text, 2, 1);
    }
  }*/


  makeGraphFromData() {
    if (this.data) {
      const cov = this.data.cov;
      this.cellIdxs = [];
      this.refIdxs = [];
      cov.p.forEach((p, i) =>
        p === 'cell' ? this.cellIdxs.push(i) : this.refIdxs.push(i));

      this.cellTextData = this.cellIdxs.map(i =>
        `cell - ${cov.Ethnicity[i]}
<br>Line: ${cov.id[i]}
<br>Tissue: ${cov.Tissue[i]}
<br>CCLE: ${cov.CCLE_membership[i]}
<br>COSMIC: ${cov.COSMIC_membership[i]}
<br>cluster/infomap: ${cov['cluster.infomap'][i]}
<br>Q1 (European, South): ${cov['Q1'][i]}
<br>Q2 (Native American): ${cov['Q2'][i]}
<br>Q3 (East Asian, South): ${cov['Q3'][i]}
<br>Q4 (East Asian, North): ${cov['Q4'][i]}
<br>Q5 (South Asian): ${cov['Q5'][i]}
<br>Q6 (European, North): ${cov['Q6'][i]}
<br>Q7 (African): ${cov['Q7'][i]}
`);
      this.refTextData = this.refIdxs.map(i => `1KG - ${cov.p[i]} - ${cov.sp[i]} - ${cov.id[i]}
<br>${kgAnnotations[cov.sp[i]]}
<br>cluster/infomap: ${cov['cluster.infomap'][i]}
<br>Q1 (European, South): ${cov['Q1'][i]}
<br>Q2 (Native American): ${cov['Q2'][i]}
<br>Q3 (East Asian, South): ${cov['Q3'][i]}
<br>Q4 (East Asian, North): ${cov['Q4'][i]}
<br>Q5 (South Asian): ${cov['Q5'][i]}
<br>Q6 (European, North): ${cov['Q6'][i]}
<br>Q7 (African): ${cov['Q7'][i]}
`);
      this.cellSymbolData = this.cellIdxs.map( () => this.defaultCellSymbol);
      this.refSymbolData = this.refIdxs.map( () => this.defaultRefSymbol);


      this.makeGraphForMode();
    }
  }
  makeGraphForMode() {
    this.graph.layout = {
      legend: {
        x: 0,
          y: 1
      },
      autosize: true,
        hovermode: 'closest',
      dragmode: null,
      margin: {
      l: 0,
        r: 0,
        t: 20,
        b: 0
    },
      xaxis: {
        zeroline: false,
          showgrid: false,
          showline: false,
          showticklabels: false
      },
      yaxis: {
        zeroline: false,
          showgrid: false,
          showline: false,
          showticklabels: false
      }
    };
    switch ( this.mode ) {
      case 'tsne':
        this.makeTS2Graph();
        break;
      case 'pca':
        this.makePCAGraph();
        break;
      case 'split':
        this.makeSplitGraph();
        break;
      case 'tsne3d':
        this.makeTS3Graph();
        break;
      case 'population-panel':
        this.makePopulationPanelGraph();
        break;
      case 'admixture-panel':
        console.time('makePanelGraph');
        this.makePanelGraph();
        console.timeEnd('makePanelGraph');
    }
  }
  makeTS2Graph() {
    console.log('making graph from data');
    const cov = this.data.cov;
    const ts2 = this.data.ts2;

    this.graph.layout.dragmode = 'zoom';
    this.graph.layout.showlegend = true;


    this.graph.data = [
      {
        x: this.refIdxs.map( i => ts2.X1[i]),
        y: this.refIdxs.map( i => ts2.X2[i]),
        type: 'scatter', mode: 'markers',
        text: this.refTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.refSymbolData,
          line: {
            width: 0
          }
        },
        name: '1KG References'
      },
      {
        x: this.cellIdxs.map( i => ts2.X1[i]),
        y: this.cellIdxs.map( i => ts2.X2[i]),
        type: 'scatter', mode: 'markers',
        text: this.cellTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.cellSymbolData,
          line: {
            width: 0,
          }
        },
        name: 'Cell Lines'
      }
    ];
    this.graph.layout.annotations = this.data.kgCentroids.map(c => ({
        x: c.X1 - 3,
        y: c.X2 + 3,
        xref: 'x',
        yref: 'y',
        text: `<b>${c.sp}</b>`,
        showarrow: false,
        ax: -20,
        ay: -40,
        font: {
          size: this.defaultAnnotationSize,
          // color: this.colorScale(c.p)
        }
      })
    );
  }
  makeTS3Graph() {
    console.log('making ts3');
    const ts3 = this.data.ts3;
    this.graph.layout.dragmode = 'rotate';
    this.graph.layout.scene = {
      "xaxis": {
        "title": '',
          "ticks": null,
          "showticklabels": false,
          "zeroline": false,
          "showgrid": false
      },
      "yaxis": {
        "title": '',
          "ticks": null,
          "showticklabels": false,
          "zeroline": false,
          "showgrid": false
      },
      "zaxis": {
        "title": '',
          "ticks": null,
          "showticklabels": false,
          "zeroline": false,
          "showgrid": false
      }
    };

    this.graph.data = [
      {
        x: this.refIdxs.map( i => ts3.X1[i]),
        y: this.refIdxs.map( i => ts3.X2[i]),
        z: this.refIdxs.map( i => ts3.X3[i]),
        type: 'scatter3d', mode: 'markers',
        text: this.refTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol:  this.refIdxs.map( () => this.defaultRef3dSymbol),
          line: {
            width: 0
          }
        },
        name: '1KG References'
      },
      {
        x: this.cellIdxs.map( i => ts3.X1[i]),
        y: this.cellIdxs.map( i => ts3.X2[i]),
        z: this.cellIdxs.map( i => ts3.X3[i]),
        type: 'scatter3d', mode: 'markers',
        text: this.cellTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.cellIdxs.map( () => this.defaultCell3dSymbol),
          line: {
            width: 0,
          }
        },
        name: 'Cell Lines'
      }
    ];
  }
  makePopulationPanelGraph() {
    this.graph.layout.dragmode = 'zoom';

    console.log('making graph from data');
    const cov = this.data.cov;
    const ts2 = this.data.ts2;

    const refX = this.refIdxs.map( i => ts2.X1[i]);
    const refY = this.refIdxs.map( i => ts2.X2[i]);

    const cellX = this.cellIdxs.map( i => ts2.X1[i]);
    const cellY = this.cellIdxs.map( i => ts2.X2[i]);

    const dim = [2, 2];

    this.graph.layout.showlegend = false;

    const nplots = 4;

    for ( let i = 1; i <= nplots; i += 1) {
      const x = Math.floor((i - 1) / dim[0]) ;
      const y = ((i - 1) % dim[0]);
      const axisX = i === 1 ? '' : '' + i;
      const axisY = i === 1 ? '' : '' + i;
      this.graph.layout['xaxis' + axisX] = {
        "domain": [
          x/dim[0],
          (x+1)/dim[0]
        ],
        "title": null,
        "ticks": null,
        "showticklabels": false,
        "zeroline": false,
        "showgrid": false,
        "anchor": 'y' + axisY
      };
      this.graph.layout['yaxis' + axisY] = {
        "domain": [
          1 - (1 + y) / dim[1],
          1 - y / dim[1]
        ],
        "title": null,
        "ticks": null,
        "showticklabels": false,
        "zeroline": false,
        "showgrid": false,
        "anchor": 'x' + axisX
      };
    }
    this.graph.data = [];
    this.graph.layout.annotations = [];
    const plotTitles = [
      '1KG Super Population',
      '1KG Population',
      '1KG Population, and Reported Ethnicity for Cell Lines',
      'Cluster by Infomap'];

    for ( let i = 1; i <= nplots; i += 1) {
      const x = Math.floor((i - 1) / dim[0]) + 1;
      const y = ((i - 1) % dim[0] + 1);
      const d = {
        x: refX,
        y: refY,
        type: 'scatter', mode: 'markers',
        text: this.refTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.refSymbolData,
          line: {
            width: 0
          }
        },
        xaxis: 'x' + i,
        yaxis: 'y' + i,
      };
      const dc = {
        x: cellX,
        y: cellY,
        type: 'scatter', mode: 'markers',
        text: this.cellTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.cellSymbolData,
          line: {
            width: 0
          }
        },
        xaxis: 'x' + i,
        yaxis: 'y' + i,
        name: 'Q' + i
      };
      // console.log(d);
      this.graph.data.push(d);
      this.graph.data.push(dc);

      this.graph.layout.annotations.push({
        x: -10,
        y: 50,
        /*
                xref:  'paper',
                yref:  'paper',
        */
        showarrow: false,
        xref:  'x' + i,
        yref:  'y' + i,
        text: plotTitles[ i - 1 ]
      });
    }
    // console.log(this.graph);
  }

  makePanelGraph() {
    this.graph.layout.dragmode = 'zoom';

    console.log('making graph from data');
    const cov = this.data.cov;
    const ts2 = this.data.ts2;

    const refX = this.refIdxs.map( i => ts2.X1[i]);
    const refY = this.refIdxs.map( i => ts2.X2[i]);

    const cellX = this.cellIdxs.map( i => ts2.X1[i]);
    const cellY = this.cellIdxs.map( i => ts2.X2[i]);

    const dim = [3, 3];

    this.graph.layout.showlegend = false;

    for ( let i = 1; i <= 7; i += 1) {
      const x = Math.floor((i - 1) / 3) ;
      const y = ((i - 1) % 3);
      const axisX = i === 1 ? '' : '' + i;
      const axisY = i === 1 ? '' : '' + i;
      this.graph.layout['xaxis' + axisX] = {
        "domain": [
          x/3,
          (x+1)/3
        ],
        "title": null,
        "ticks": null,
        "showticklabels": false,
        "zeroline": false,
        "showgrid": false,
        "anchor": 'y' + axisY
      };
      this.graph.layout['yaxis' + axisY] = {
        "domain": [
          (2-y)/3,
          1-y/3
        ],
        "title": null,
        "ticks": null,
        "showticklabels": false,
        "zeroline": false,
        "showgrid": false,
        "anchor": 'x' + axisX
      };
    }
    this.graph.data = [];
    this.graph.layout.annotations = [];
    const QTitles = [
      'Q1 (European, South)',
      'Q2 (Native American)',
      'Q3 (East Asian, South)',
      'Q4 (East Asian, North)',
      'Q5 (South Asian)',
      'Q6 (European, North)',
      'Q7 (African)'];

    for ( let i = 1; i <= 7; i += 1) {
      const x = Math.floor((i - 1) / 3) + 1;
      const y = ((i - 1) % 3 + 1);
      const d = {
        x: refX,
        y: refY,
        type: 'scatter', mode: 'markers',
        text: this.refTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.refSymbolData,
          line: {
            width: 0
          }
        },
        xaxis: 'x' + i,
        yaxis: 'y' + i,
        name: 'Q' + i
      };
      const dc = {
        x: cellX,
        y: cellY,
        type: 'scatter', mode: 'markers',
        text: this.cellTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.cellSymbolData,
          line: {
            width: 0
          }
        },
        xaxis: 'x' + i,
        yaxis: 'y' + i,
        name: 'Q' + i
      };
      // console.log(d);
      this.graph.data.push(d);
      this.graph.data.push(dc);

      this.graph.layout.annotations.push({
        x: -10,
        y: 50,
/*
        xref:  'paper',
        yref:  'paper',
*/
        showarrow: false,
        xref:  'x' + i,
        yref:  'y' + i,
        text: QTitles[ i - 1 ]
      });
    }
    // console.log(this.graph);
  }
  makeSplitGraph() {
    this.graph.layout.dragmode = 'zoom';

    console.log('making graph from data');
    const cov = this.data.cov;
    const ts2 = this.data.ts2;
    const pcs = this.data.pcs;

    this.graph.layout.showlegend = false;
    this.graph.layout.xaxis.domain = [0, 0.48];
    this.graph.layout.xaxis2 = {
      "domain": [
        0.52,
        1
      ],
      "title": null,
      "ticks": null,
      "showticklabels": false,
      "zeroline": false,
      "showgrid": false,
      "anchor": "y2"
    };
    this.graph.layout.yaxis.domain = [0, 1];
    this.graph.layout.yaxis2 = {
      "domain": [
        0,
        1
      ],
        "title": null,
        "ticks": null,
        "showticklabels": false,
        "zeroline": false,
        "showgrid": false,
        "anchor": "x2"
    };

    this.graph.data = [
      {
        x: this.refIdxs.map( i => ts2.X1[i]),
        y: this.refIdxs.map( i => ts2.X2[i]),
        type: 'scatter', mode: 'markers',
        text: this.refTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.refSymbolData,
          line: {
            width: 0
          }
        },
        xaxis: 'x',
        yaxis: 'y',
        name: 'tSNE (1KG References)'
      },
      {
        x: this.cellIdxs.map( i => ts2.X1[i]),
        y: this.cellIdxs.map( i => ts2.X2[i]),
        type: 'scatter', mode: 'markers',
        text: this.cellTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.cellSymbolData,
          line: {
            width: 0,
          }
        },
        xaxis: 'x',
        yaxis: 'y',
        name: 'tSNE (Cell Lines)'
      },
      {
        x: this.refIdxs.map( i => pcs.PC1[i]),
        y: this.refIdxs.map( i => pcs.PC2[i]),
        type: 'scatter', mode: 'markers',
        text: this.refTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.refSymbolData,
          line: {
            width: 0
          }
        },
        xaxis: 'x2',
        yaxis: 'y2',
        name: 'PCs (1KG References)'
      },
      {
        x: this.cellIdxs.map( i => pcs.PC1[i]),
        y: this.cellIdxs.map( i => pcs.PC2[i]),
        type: 'scatter', mode: 'markers',
        text: this.cellTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.cellSymbolData,
          line: {
            width: 0,
          }
        },
        xaxis: 'x2',
        yaxis: 'y2',
        name: 'PCs (Cell Lines)'
      }
    ];
  }
  makePCAGraph() {
    this.graph.layout.dragmode = 'zoom';

    console.log('making graph from data');
    const cov = this.data.cov;
    const pcs = this.data.pcs;

    this.graph.data = [
      {
        x: this.refIdxs.map( i => pcs.PC1[i]),
        y: this.refIdxs.map( i => pcs.PC2[i]),
        type: 'scatter', mode: 'markers',
        text: this.refTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.refSymbolData,
          line: {
            width: 0
          }
        },
        name: '1KG References'
      },
      {
        x: this.cellIdxs.map( i => pcs.PC1[i]),
        y: this.cellIdxs.map( i => pcs.PC2[i]),
        type: 'scatter', mode: 'markers',
        text: this.cellTextData,
        hoverinfo: 'text',
        marker: {
          // color: colorData.map(m => this.colorScale(m)),
          // showscale: this.colorBy !== null,
          symbol: this.cellSymbolData,
          line: {
            width: 0,
          }
        },
        name: 'Cell Lines'
      }
    ];
  }

  setupColorsForMarkers(refMarker, cellMarker, colorBy, annotations= null, cmin= null, cmax= null, showscale= true, ) {
    const dcbc = this.defaultCellBorderColor;

    const darker = function(color) {
      if ( !isNaN(color)) {
        // console.log(color);
        return color;
      }
      if (color.startsWith('#')) {
        // hex color
        color = hexToRgbA(color);
      }
      if (color.startsWith('rgba')) {
        // color looks like this  'rgba(0,0,0,0)'
        const col = color.slice(5, -1).split(',');
        const newcol = col.slice(0, -1).map(c => parseInt(c, 10))
          .map(c => c - 40 < 0 ? 0 : c - 40);
        newcol.push(col[col.length - 1]);
        return 'rgba(' + newcol.join() + ')';
      } else {
        return dcbc;
      }
    }
/*
    if (this.mode === 'tsne-cluster') {
      colorBy = colorBy ? colorBy : {attr: 'cluster.infomap', continuous: false};
    }
*/

    const cov = this.data.cov;
    // console.log(colorBy);
    if ( !colorBy ) {
      // refMarker.color = this.refIdxs.map(i => this.colorScale(cov.p[i] + '-' + cov.sp[i]));
      refMarker.color = this.refIdxs.map(i => this.colorScale(cov.sp[i]));
      cellMarker.color = this.cellIdxs.map(i => this.colorScale('cell' + '-' + cov.Ethnicity[i]));
      cellMarker.showscale = false;
      refMarker.showscale = false;
      if (annotations) {
        annotations.forEach( (a, i) => {
            a.font.color = this.colorScale(this.data.kgCentroids[i].sp);
        });
      }
      // const cellColorData = cellCov.p.map(p => this.colorScale('cell'));
      // this.cellColorData = this.cellCov.p.map(p => this.defaultCellColor);
    } else {
      const attr = colorBy.attr;
      if ( ! colorBy.continuous ) {
        refMarker.color = this.refIdxs.map(i => this.colorScale(cov[attr][i]));
        cellMarker.color = this.cellIdxs.map(i => cov[attr][i] === 'cell' ? this.defaultCellColor : this.colorScale(cov[attr][i]));
        // cellMarker.color = this.cellIdxs.map(i => this.colorScale(cov[attr][i]));
        cellMarker.showscale = false;
        refMarker.showscale = false;
        if (annotations) {
          annotations.forEach( (a, i) => {
              a.font.color = attr in this.data.kgCentroids[i] ? this.colorScale(this.data.kgCentroids[i][attr]) : null;
          });
        }

      } else {
        refMarker.color = this.refIdxs.map(i => cov[attr][i]);
        cellMarker.color = this.cellIdxs.map(i => cov[attr][i]);
        if ( cmin === null ) {
          cmin = Math.min(...refMarker.color, ...cellMarker.color);
        }
        if ( cmax === null ) {
          cmax = Math.max(...refMarker.color, ...cellMarker.color);
        }
        cellMarker.colorscale = [[0, 'rgb(220,220,220)'], [1, hexToRgb(this.colorScale10(attr))]];
        refMarker.colorscale = [[0, 'rgb(220,220,220)'], [1, hexToRgb(this.colorScale10(attr))]];
/*
        cellMarker.colorscale = [[cmin, hexToRgbA('#000000')], [cmax, hexToRgbA(this.colorScale(attr))]];
        refMarker.colorscale = [[cmin, hexToRgbA('#000000')], [cmax, hexToRgbA(this.colorScale(attr))]];
*/
/*
        cellMarker.colorscale = [[cmin, 'rgba(0,0,0,0)' ], [cmax, this.colorScale(attr)]];
        refMarker.colorscale = [[cmin, 'rgba(0,0,0,0)' ], [cmax, this.colorScale(attr)]];
*/
        cellMarker.showscale = showscale;
        cellMarker.cmin = cmin;
        cellMarker.cmax = cmax;
        cellMarker.colorbar = {title: colorBy.attr};
        refMarker.showscale = showscale;
        refMarker.cmax = cmax;
        refMarker.cmin = cmin;
        refMarker.colorbar = {title: colorBy.attr};
        if (annotations) {
          annotations.forEach( (a, i) =>
            a.font.color = null
          );
        }
      }
    }
    cellMarker.line.color = cellMarker.color.map( c => darker(c));
    // this.cellBorderColorData = this.cellColorData.map(c => darker(c));
    if ( this.mode === 'split') {
      const ref2Marker = this.graph.data[2].marker;
      const cell2Marker = this.graph.data[3].marker;
      ref2Marker.color = refMarker.color;
      cell2Marker.color = cellMarker.color;
      cell2Marker.line.color = cellMarker.line.color;
    }
  }

  setupColors() {
    // if supplying a colorby attribute, use it,
    //        discrete or continuous?
    //        formula?
    // else use default scheme:
    //        discrete p-sp labels

    // Can't be too general at this point
    // colorby will be predefined values: discrete or continuous
    switch (this.mode) {
      case 'admixture-panel':
        for (let i = 1; i <= 7; i++) {
          const colorBy = {attr: 'Q' + i, continuous: true};
          this.setupColorsForMarkers(this.graph.data[i * 2 - 2].marker, this.graph.data[i * 2 - 1].marker, colorBy, null,0, 1, false);
        }
        break;
      case 'population-panel':
        const colorBys = [
          {attr: 'p', continuous: false},
          {attr: 'sp', continuous: false},
          null,
          {attr: 'cluster.infomap', continuous: false}
        ];
        colorBys.forEach((c, i) =>
          this.setupColorsForMarkers(this.graph.data[i * 2].marker,
            this.graph.data[i * 2 + 1].marker, c));
        break;
      default:
        this.setupColorsForMarkers(this.graph.data[0].marker, this.graph.data[1].marker, this.colorBy,
          this.graph.layout.annotations);

    }
  }
  doSearch() {
    console.log('doing search');
    const cov = this.data.cov;
    if ( (! this.cellIdxs) && (! this.refIdxs)) {
      this.cellIdxs = [];
      this.refIdxs = [];
      cov.p.forEach((p, i) =>
        p === 'cell' ? this.cellIdxs.push(i) : this.refIdxs.push(i));
    }

    if (this.search && this.search.length > 0 ) {
      this.selectedRefPoints = this.refIdxs.map( i => this.search.some(
        p =>
          '1KG'.match(new RegExp('\\b' + p + '\\b', 'i')) ||
          cov.id[i].match(new RegExp('\\b' + p + '\\b', 'i')) ||
          cov.p[i].match(new RegExp('\\b' + p + '\\b', 'i')) ||
          cov.sp[i].match(new RegExp('\\b' + p + '\\b', 'i'))

      ));
      this.selectedCellPoints = this.cellIdxs.map( i => this.search.some(
        p =>
          p === 'cell' ||
          cov.id[i].match(new RegExp('\\b' + p + '\\b', 'i')) ||
          cov.Ethnicity[i].match(new RegExp('\\b' + p + '\\b', 'i')) ||
          cov.Tissue[i].match(new RegExp('\\b' + p + '\\b', 'i'))
      ));
    }
    this.selectedCellIdxs = this.cellIdxs;
    if ( this.search && this.search.length > 0) {
      this.selectedCellIdxs = [];
      this.selectedCellPoints.forEach((s, i) => {
        if (s) {
          this.selectedCellIdxs.push(this.cellIdxs[i]);
        }
      });
    }
    this.selectedRefIdxs = this.refIdxs;
    if (  this.search && this.search.length > 0 ) {
      this.selectedRefIdxs = [];
      this.selectedRefPoints.forEach((s, i) => {
        if (s) {
          this.selectedRefIdxs.push(this.refIdxs[i]);
        }
      });
    }

    this.makeCovRowData();
    this.searchResults.emit(this.tabulateSearchResults());
  }
  setupSizesAndOpacityForMarkers(refMarker, cellMarker,  annotations= null) {
    if (this.search && this.search.length > 0) {
      refMarker.opacity = this.selectedRefPoints.map( s => s ? this.highlightOpacity : this.backgroundOpacity);
      refMarker.size = this.selectedRefPoints.map( s => s ? this.highlightSize : this.backgroundSize);
      refMarker.line.opacity = this.selectedRefPoints.map( s => s ? this.highlightOpacity : this.backgroundOpacity);
      cellMarker.opacity = this.selectedCellPoints.map( s => s ? this.highlightOpacity : this.backgroundOpacity);
      cellMarker.size = this.selectedCellPoints.map( s => s ? this.highlightSize : this.backgroundSize);
      cellMarker.line.opacity = this.selectedCellPoints.map( s => s ? this.highlightOpacity : this.backgroundOpacity);
      cellMarker.line.width = this.selectedCellPoints.map( s => s ? this.highlightBorderWidth : this.backgroundBorderWidth);

      if (annotations) {
        annotations.forEach( a => {
          a.opacity = this.selectedRefIdxs.some( i => a.text.includes(
            this.data.cov.sp[i])) ?
            this.highlightOpacity : this.backgroundOpacity;
        });
      }

      // d.marker.line = {};
      // d.marker.line.color = makeSelectedArray(d.text, newcolv, oldcolv);
      // refData.marker.line.width = makeSelectedArray(d.text, 2, 1);
    } else {
      refMarker.opacity = this.refIdxs.map(rc => this.defaultRefOpcacity);
      cellMarker.opacity = this.cellIdxs.map(rc => this.defaultCellOpacity);
      refMarker.line.opacity = this.refIdxs.map( rc => this.defaultCellOpacity);
      cellMarker.line.opacity = this.cellIdxs.map( rc => this.defaultCellOpacity);
      refMarker.size = this.refIdxs.map(rc => this.defaultRefSize);
      cellMarker.size = this.cellIdxs.map(rc => this.defaultCellSize);
      if (annotations) {
        annotations.forEach( a => {
          a.opacity = this.defaultAnnotationOpcacity;
        });
      }
    }
    if ( this.mode === 'split') {
      const ref2Marker = this.graph.data[2].marker;
      const cell2Marker = this.graph.data[3].marker;
      ref2Marker.opacity = refMarker.opacity;
      ref2Marker.size = refMarker.size;
      cell2Marker.opacity = cellMarker.opacity;
      cell2Marker.size = cellMarker.size;
      ref2Marker.line.opacity = refMarker.line.opacity;
      cell2Marker.line.opacity = cellMarker.line.opacity;
    }
  }

  setupSizesAndOpacity() {
    switch (this.mode) {
      case 'admixture-panel':
        for (let i = 1; i <= 7; i++) {
          this.setupSizesAndOpacityForMarkers(this.graph.data[i * 2 - 2].marker,
            this.graph.data[i * 2 - 1].marker);
        }
        break;
      case 'population-panel':
        for (let i = 1; i <= 4; i++) {
          this.setupSizesAndOpacityForMarkers(this.graph.data[i * 2 - 2].marker,
            this.graph.data[i * 2 - 1].marker);
        }
        break;
      default:
        this.setupSizesAndOpacityForMarkers(this.graph.data[0].marker, this.graph.data[1].marker, this.graph.layout.annotations);
    }
  }

  tabulate(idxs, attr) {
    const tab = {};
    const cov = this.data.cov;
    idxs.forEach( (i) => {
      const v = cov[attr][i];
      if (! (v in tab )) {
        tab[v] = 1;
      } else {
        tab[v]++;
      }
    });
    // console.log(Object.keys(tab).map( k => ({value: k, count: tab[k]})));
    return Object.keys(tab).map( k => ({value: k, count: tab[k]})).sort(
      (a, b) => b.count - a.count);
  }

  tabulateAllData() {
    if ( ! this.data) {
      return null;
    }
    const cov = this.data.cov;
    return {refCount: this.refIdxs.length, ref: this.tabulate(this.refIdxs, 'sp'),
      cellCount: this.cellIdxs.length, cell: this.tabulate(this.cellIdxs, 'Ethnicity')};
  }
  tabulateSearchResults() {
    if ( (!this.search) || this.search.length === 0 ) {
      return this.tabulateAllData();
    }
    const cov = this.data.cov;
    const selectedRefIdxs = [];
    const selectedCellIdxs = [];
    if ( this.selectedRefPoints ) {
      this.selectedRefPoints.forEach((s, i) => {
        if (s) {
          selectedRefIdxs.push(this.refIdxs[i]);
        }
      });
    }
    if ( this.selectedCellPoints ) {
      this.selectedCellPoints.forEach((s, i) => {
        if (s) {
          selectedCellIdxs.push(this.cellIdxs[i]);
        }
      });
    }
    return {refCount: selectedRefIdxs.length, ref: this.tabulate(selectedRefIdxs, 'sp'),
      cellCount: selectedCellIdxs.length, cell: this.tabulate(selectedCellIdxs, 'Ethnicity')};
  }

  makeCovRowData() {
    this.clCovColumnDefs = [
      {headerName: 'ID', field: 'id', pinned: 'left'},
      {headerName: 'Aliases', field: 'Aliases'},
      {headerName: 'CVCL', field: 'RRID_CVCLE',
        cellRenderer: function(params) {
          return '<a href="https://web.expasy.org/cellosaurus/CVCL'
            + params.value
            + '">' + params.value + '</a>';
        }
      },
      {headerName: 'Ethnicity', field: 'Ethnicity'},
      {headerName: 'Tissue', field: 'Tissue'},
      {headerName: 'Histology', field: 'Histology'},
      {headerName: 'Subhistology', field: 'Subhistology'},
      {headerName: 'Age', field: 'Age', type: "numericColumn"},
      {headerName: 'Source', field: 'Source'},
      {headerName: 'CCLE_membership', field: 'CCLE_membership'},
      {headerName: 'CCLE_membership', field: 'NCI60_membership'},
      {headerName: 'Eur-south', field: 'Q1', type: "numericColumn"},
      {headerName: 'Native American', field: 'Q2', type: "numericColumn"},
      {headerName: 'E.Asian-south', field: 'Q3', type: "numericColumn"},
      {headerName: 'E.Asian-north', field: 'Q4', type: "numericColumn"},
      {headerName: 'S.Asian', field: 'Q5', type: "numericColumn"},
      {headerName: 'Eur-north', field: 'Q6', type: "numericColumn"},
      {headerName: 'African', field: 'Q7', type: "numericColumn"}
    ];
    const cov = this.data.cov;
    this.clCovRowData = this.selectedCellIdxs.map(idx => {
      const ret = {};
      for ( const key of Object.keys(cov) ) {
        ret[key] = cov[key][idx];
        if (ret[key] === 'NA') {
          ret[key] = null;
        }
      }
      return ret;
    });

    this.refCovColumnDefs = [
      {headerName: 'ID', field: 'id'},
      {headerName: 'sp', field: 'p'},
      {headerName: 'p', field: 'sp'},
      {headerName: 'Eur-south', field: 'Q1', type: "numericColumn", filter: 'agSetColumnFilter'},
      {headerName: 'Native American', field: 'Q2', type: "numericColumn"},
      {headerName: 'E.Asian-south', field: 'Q3', type: "numericColumn"},
      {headerName: 'E.Asian-north', field: 'Q4', type: "numericColumn"},
      {headerName: 'S.Asian', field: 'Q5', type: "numericColumn"},
      {headerName: 'Eur-north', field: 'Q6', type: "numericColumn"},
      {headerName: 'African', field: 'Q7', type: "numericColumn"}
    ];

    this.refCovRowData = this.selectedRefIdxs.map( idx => {
      const ret = {};
      for ( const key of Object.keys(cov) ) {
        ret[key] = cov[key][idx];
      }
      return ret;
    });


  }

  ngOnChanges(changes) {
    console.log('ngonchanges');
    // console.log(changes);
    // console.log(this.data);

    // do search first
    if (changes.search) {
      this.doSearch();
    }

    if (changes.data || changes.mode) {
      // makeGraphFromData should use mode, highlight and color by
      this.makeGraphFromData();
      this.setupColors();
      this.setupSizesAndOpacity();
      if (changes.data) {
        this.makeCovRowData();
      }
      console.log(this.graph.data);
      return;
    }


    /*
        if (changes.mode) {
          this.makeGraphForMode();
          this.setupColors();
          this.setupSizesAndOpacity();
          return;
        }
    */
    if (changes.colorBy) {
      this.setupColors();
      console.log(this.graph.data);

      // this.updateColor();
    }
    if (changes.search) {
      this.setupSizesAndOpacity();
    }
  }

  doShowWhatChange() {
    switch (this.showWhat) {
      case 'clTable':
        if (this.cellGridNotFitted) {
          // this.cellGrid.api.sizeColumnsToFit();
          this.cellGridNotFitted = false;
        }
        break;
      case 'refTable':
        if (this.refGridNotFitted) {
          this.refGrid.api.sizeColumnsToFit();
          // this.refGridNotFitted = false;
        }
        break;
    }
  }
  exportCSV() {
    switch (this.showWhat) {
      case 'clTable':
        this.cellGrid.api.exportDataAsCsv({fileName: 'cell-lines.csv'});
        break;
      case 'refTable':
        this.refGrid.api.exportDataAsCsv({fileName: '1kg-refs.csv'});
        break;
    }

  }
/*
  updateColor() {
      console.log('updating color');
      const colorData = this.colorBy;
        /!*? this.colorBy :
        this.data['nonmalig.cell.type'].map(m => this.colorScale(m));
*!/
      this.graph.data = this.graph.data.slice();
      this.graph.data[0].marker.color = colorData;
  }
  updateDisplayMode() {

  }
*/
/*

  updateData() {
    console.log('updating data');
    this.backgroundOpacity = this.blurBackground ? 0.1 : 0.5;
    // this.data[this.mode].data.forEach(d => this.markData(d));
  }

*/

}
