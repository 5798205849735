import { Injectable } from '@angular/core';
import {PlotlyService} from 'angular-plotly.js';

@Injectable()
export class MyPlotlyService extends PlotlyService {

  constructor() {
    super();
  }
  get d3() {
    return this.plotly.d3;
  }
  relayout(div, ed) {
    this.plotly.relayout(div,ed);
  }
  animate(div, graph, transition) {
    console.log(this.plotly.animate);
    this.plotly.animate(div, graph, transition);
  }
}

