import { Component, OnInit } from '@angular/core';
import { versions } from '../../../environments/versions';
import {Router} from '@angular/router';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
  versions = versions;

  constructor(private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      console.log("navigating to home");
      this.router.navigate(['/home']);
    }, 1000);

  }

}
