import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { versions } from '../../../environments/versions';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent, MatChipInputEvent, MatDialog, MatDialogConfig} from '@angular/material';


// import {data as ts2Data} from '../../../environments/data/ts.2.cell-line-v2-rm-discord-projection-all-maf-0.05-indep-50.10.0.8-pc100';
import {data as ts2Data} from '../../../environments/data/combined.data.all.v2.rm.discordant.maf.0.05.indep_50_10_0.8';
import {AboutDialogComponent} from '../about-dialog/about-dialog.component';
import {TranslateService} from '@ngx-translate/core';

// import { data } from '../../../environments/data/cell-line-pca'

import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // blurBackground = false;
  // showWhat = 'plot';
  openDrawer = true;
  drawerMode = 'side';
  versions = versions;
  mode = 'tsne';
  defaultColorBy = {attr: 'sp', continuous: false};
  colorBy = this.defaultColorBy;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  ts2Data = ts2Data;

  showingDetail = false;
  searchResults;


  separatorKeysCodes = [ENTER, COMMA];

  filterCtrl = new FormControl();

  filteredFilters: Observable<any[]>;

  filters = [
  ];

  allCellLines = ts2Data.cov.cLine_ID.filter((c, i) => ts2Data.cov.p[i] === 'cell');
/*
  allCellLines = data.data.filter(d => d.name.includes('cell')).
    reduce((r, c) => {
    if (!Array.isArray(c.text)) {
      r.push(/Line: (.*)[<|$]/.exec(c.text)[1]);
    } else {
      c.text.forEach(t => r.push(/Line: (.*)[<|$]/.exec(t)[1]));
    }
    return r;
  }, []);
*/
  allFilters = [
/*    'cell',
    'AMR',
    'CHB',
    'EUR',
    'AFR',
    'EAS'*/].concat(this.allCellLines);

  @ViewChild('filterInput') filterInput: ElementRef;
  @ViewChild('plot') plot;

  ngOnInit() {
  }
  constructor(public dialog: MatDialog, breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.activateHandsetLayout();
      }
    });

    breakpointObserver.observe([
      Breakpoints.WebLandscape,
      Breakpoints.WebPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.activateWebLayout();
      }
    });

    this.filteredFilters = this.filterCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this.filter(fruit) : this.allFilters.slice()));
  }
  activateHandsetLayout() {
    // this.openDrawer = true;
    this.drawerMode = 'over';
  }
  activateWebLayout() {
    // this.openDrawer = true;
    this.drawerMode = 'side';
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.filters.push(value.trim());
      this.filters = this.filters.slice();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.filterCtrl.setValue(null);
  }

  remove(fruit: any): void {
    const index = this.filters.indexOf(fruit);

    if (index >= 0) {
      console.log(this.filters);
      this.filters.splice(index, 1);
      this.filters = this.filters.slice();
    }
  }

  filter(name: string) {
    return this.allFilters.filter(fruit =>
      fruit.toLowerCase().includes(name.toLowerCase()));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.filters.push(event.option.viewValue);
    this.filters = this.filters.slice();
    this.filterInput.nativeElement.value = '';
    this.filterCtrl.setValue(null);
  }
  setSearchResults(event) {
    this.searchResults = event;
    console.log(event);
  }

  showAboutModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(AboutDialogComponent, dialogConfig);
  }

}
