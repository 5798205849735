import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';

// import { data as dataTsne } from '../../../environments/data/cell-line-projection-all-maf-0.05-indep-50.10.0.8-exflip-neg-gt-1';
//import { data as dataTsne } from '../../../environments/data/cell-line-v2-projection-all-indep-50.10.0.8';
/*
import { data as dataTsneOld } from '../../../environments/data/cell-line-v2-projection-all-pc100';
// import { data as dataTsne } from '../../../environments/data/cell-line-v2-projection-all-pc100-try-symbols';
import { data as dataTsne } from '../../../environments/data/cell-line-v2-rm-discord-projection-all-maf-0.05-indep-50.10.0.8-pc100';
// import { data as dataTsne3d } from '../../../environments/data/cell-line-projection-3d-all-maf-0.05-indep-50-10-0.8';
import { data as dataTsne3d } from '../../../environments/data/cell-line-v2-rm-discord-projection-3d-all-maf-0.05-indep-50.10.0.8-pc100';
import { data as dataPca } from '../../../environments/data/cell-line-v2-rm-discord-pca-all-maf-0.05-indep-50.10.0.8';
import { data as dataCombined } from '../../../environments/data/cell-line-v2-rm-discord-combined-pca-tsne';
*/

@Component({
  selector: 'app-cell-line-ancestry-plot',
  templateUrl: './cell-line-ancestry-plot.component.html',
  styleUrls: ['./cell-line-ancestry-plot.component.scss']
})
export class CellLineAncestryPlotComponent implements OnInit, OnChanges {
  data = {
/*
    'split': dataCombined,
    'tsne': dataTsne,
    'tsne-old': dataTsneOld,
    'pca': dataPca,
    'tsne3d': dataTsne3d
*/
  };
  @Input('mode') mode = 'tsne';
  @Input('selectedPoints') selectedPoints = [];
  @Input('blurBackground') blurBackground = false;
  @ViewChild('plot') plot;
  backgroundOpacity = 0.5;
  defaultRefOpcacity = 1;
  defaultCellOpacity = 1;

  colors = new Set();
  constructor() {
  }

  ngOnInit() {
  }
  ngOnChanges() {
      this.updateData();
  }
  markData(d) {
    const makeSelectedArray = (text, selectedV, notSelectedV ) =>
      Array.isArray(text) ?
        text.map(t => this.selectedPoints.some(
          p => t.match(new RegExp('\\b' + p + '\\b', 'i'))
        ) ? selectedV : notSelectedV) :
        this.selectedPoints.some(
          p => text.match(new RegExp('\\b' + p + '\\b', 'i'))
        ) ? selectedV : notSelectedV;

    // color looks like this  'rgba(0,0,0,0)'
    this.colors.add(d.marker.color);
    const oldcolv = d.marker.color;
    const col = oldcolv.slice(5, -1).split(',');
    const newcol = col.slice(0, -1).map(c => parseInt(c, 10))
      .map(c => c - 40 < 0 ? 0 : c - 40);
    newcol.push(col[col.length - 1]);
    const newcolv = 'rgba(' + newcol.join() + ')';
    if ( (!this.selectedPoints) || this.selectedPoints.length === 0) {
      // nothing is selected
      // set all markers' opacity to 1.0
      d.marker.opacity =  Array.isArray(d.text) ? d.text.map(t =>
          (t.startsWith('cell') ? this.defaultCellOpacity : this.defaultRefOpcacity)) :
        (d.text.startsWith('cell') ? this.defaultCellOpacity : this.defaultRefOpcacity);
      d.marker.size = Array.isArray(d.text) ? d.text.map(t => 6.0) : 6.0;
      d.marker.line = {
        color: newcolv,
        width: 1,
        opacity: Array.isArray(d.text) ? d.text.map(t =>
            (t.startsWith('cell') ? this.defaultCellOpacity : this.defaultRefOpcacity)) :
          (d.text.startsWith('cell') ? this.defaultCellOpacity : this.defaultRefOpcacity)
      };
    } else {
      d.marker.opacity = makeSelectedArray(d.text, 1.0, this.backgroundOpacity);
      d.marker.size = makeSelectedArray(d.text, 10.0, 4.0);
      d.marker.line = {};
      d.marker.line.color = makeSelectedArray(d.text, newcolv, oldcolv);
      d.marker.line.opacity = makeSelectedArray(d.text, 1.0, this.backgroundOpacity);
      d.marker.line.width = makeSelectedArray(d.text, 2, 1);
/*
      console.log(d.text);
      console.log(d.marker.color);
      console.log(d.marker.line);
*/

    }
/*
    console.log(d.name);
    console.log(d.marker.opacity);
*/

/*
    d.marker.opacity = this.selected(d) ? 1.0 : 0.1;
*/
  }

/*
  selected(d) {
    if ( (!this.selectedPoints) || this.selectedPoints.length === 0) {
      return true;
    }
    return this.selectedPoints.some(p => d.name.includes(p));
  }
*/
  updateData() {
    console.log('updating data');
    this.backgroundOpacity = this.blurBackground ? 0.1 : 0.5
    this.data[this.mode].data.forEach(d => this.markData(d));
    console.log(this.colors);
  }
  selectPoints(points) {
    this.selectedPoints = points;
    console.log(this.data[this.mode].data[0]);
    this.updateData();
    this.plot.updatePlot();
  }
}
